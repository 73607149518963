



import { Component, Mixins } from 'vue-property-decorator'
import Explanation from '@/components/organisms/study/Explanation.vue'
import Contents1 from '@/views/student/study/Contents1.vue'

@Component({
  components: {
    Explanation,
  },
})
export default class Explanation9 extends Mixins(Contents1) {}
